import * as React from 'react'

import Page from '../components/Page'
import HeroBanner from '../components/HeroBanner'
import SectionServices from '../components/SectionServices'
import SectionExperience from '../components/SectionExperience'
import SectionTeam from '../components/SectionTeam'
import SectionPositions from '../components/SectionPositions'
import SectionContact from '../components/SectionContact'
import IndexLayout from '../layouts'

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <HeroBanner
        id="top"
        css={{
          animation: 'kenburns-top 5s ease-out infinite alternate both'
        }}
      />
      <SectionServices id="services" />
      <SectionExperience id="experience" />
      <SectionTeam id="team" />
      <SectionPositions id="positions" />
      <SectionContact id="contact" />
    </Page>
  </IndexLayout>
)

export default IndexPage
