import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'

import SectionContainer from './SectionContainer'
import CardTeam, { ICardProps } from './CardTeam'
import { colors, widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { useSalProps } from '../helpers/useSalAnim'

const StyledContainer = styled(SectionContainer)`
  background-color: ${colors.gray.dark};
  flex-direction: column;
  padding: 50px 0;
  align-items: center;
`

const SectionHeading = styled.h2`
  text-transform: uppercase;
  text-align: center;
  color: ${colors.white};
  position: relative;
  padding: 0.5em;
  margin-bottom: 50px;
  font-size: ${getEmSize(45)}em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    width: 80px;
    background: ${colors.white};
    height: 3px;
  }
`

const Intro = styled(Box)`
  font-size: ${getEmSize(24)}em;
  color: ${colors.gray.calm};
  text-align: center;
  margin-bottom: 50px;
  padding: 0 20px;
`

const Cards = styled(Flex)`
  width: 100%;
  max-width: ${widths.maxOuter}px;
  justify-content: center;
  align-self: center;
  margin-bottom: 50px;
  z-index: 200;
`

const SectionTeam = () => (
  <StaticQuery
    query={graphql`
      query TeamFromJSON {
        images: allFile(filter: { absolutePath: { regex: "/team/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 350, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        datafromjson: allTeamJson {
          edges {
            node {
              heading
              intro
              team {
                name
                position
                profileImage
                linkedIn
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { images, datafromjson } = data
      const { heading, intro, team } = datafromjson.edges[0].node
      const salProps = useSalProps()
      return (
        <StyledContainer id="team">
          <SectionHeading {...salProps}>{heading}</SectionHeading>
          <Intro {...salProps} data-sal-delay="200" width={[1, 4 / 5, , 4 / 5, 1 / 2]}>
            {intro}
          </Intro>
          <Cards flexWrap="wrap">
            {team.map((member, index: number) => {
              const teamIndex = images.edges.findIndex(
                y =>
                  y.node.name ===
                  member.profileImage
                    .split('.')
                    .slice(0, -1)
                    .join('.')
              )
              const image = images.edges[teamIndex].node.childImageSharp.fluid
              const { name, position, linkedIn } = member
              return (
                <CardTeam
                  key={index}
                  name={name}
                  image={image}
                  position={position}
                  linkedIn={linkedIn}
                  width={[1, 1 / 2, 1 / 4]}
                  {...salProps}
                />
              )
            })}
          </Cards>
        </StyledContainer>
      )
    }}
  />
)

export default SectionTeam
