import * as React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import 'balloon-css'

import { getEmSize } from '../styles/mixins'
import { colors, widths, mq } from '../styles/variables'

import IconVisit from '../images/icons/icon_visit_off.svg'
import IconDownload from '../images/icons/icon_download_off.svg'
import IconPlay from '../images/icons/icon_play.svg'

export interface ICardProps {
  title: string
  content: string
  logo: string
  website: string
  pdf: string
  caseStudy?: string
  [x: string]: any
}

const CardContainer = styled.div`
  background: ${colors.white};
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  max-width: 450px;
  min-width: 320px;
  width: 100%;
  padding: 30px 30px 10px 30px;
  margin: 0 10px 20px;
  transition: all 0.3s ease-out;
  color: ${colors.black};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq('md')} {
    padding: 50px 30px 10px 30px;
  }
`
const CardHeading = styled.h3`
  text-align: left;
  position: relative;
  padding-top: 0.5em;
  margin: 1em 0 0.5em;
  font-size: ${getEmSize(24)}em;

  ${mq('md')} {
    margin: 2em 0 0.5em;
  }
`
const LogoContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
`

const CardExperience: React.FC<ICardProps> = ({ width, title, content, logo, website, pdf, caseStudy, ...props }) => (
  <CardContainer {...props}>
    <div>
      <LogoContainer>
        <Img
          css={css`
            display: flex;
            margin: 0 auto;
          `}
          imgStyle={{
            objectFit: 'contain'
          }}
          fixed={logo}
        />
      </LogoContainer>
      <CardHeading>{title}</CardHeading>
      <p
        css={{
          fontSize: `${getEmSize(18)}em`,
          // paddingRight: '30px',
          textAlign: 'left',
          [mq('md')]: {
            paddingRight: '30px'
          }
        }}
      >
        {content}
      </p>
    </div>
    <div
      css={css`
        margin-top: 40px;
        align-self: baseline;
        display: inline-flex;

        a {
          svg {
            transition: opacity 200ms ease-in-out;

          }
        }
        a:hover {
          svg {
            /* stroke: ${colors.white};*/
            opacity: 0.6;
            transition: opacity 200ms ease-in-out;

          }
        }
      `}
    >
      {caseStudy && (
        <a href={`/case-study/${caseStudy}`} css={{ marginRight: 20 }} aria-label="View project" data-balloon-pos="down">
          <IconPlay />
        </a>
      )}
      {website && (
        <a href={website} target="_blank" aria-label="Visit website" rel="noopener noreferrer" data-balloon-pos="down">
          <IconVisit />
        </a>
      )}
      {/* {pdf && (
        <a href={pdf} target="_blank" aria-label="Download PDF" data-balloon-pos="down">
          <IconDownload />
        </a>
      )} */}
    </div>
  </CardContainer>
)

export default CardExperience
