import * as React from 'react'
import { Flex, Box } from 'reflexbox'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Img from 'gatsby-image'

import { getEmSize } from '../styles/mixins'
import { colors, widths, mq } from '../styles/variables'

import IconArch from '../images/icons/Architecture_Shapes.svg'
import IconGov from '../images/icons/Gov_Shapes.svg'
import IconStrat from '../images/icons/Strategy_Shapes.svg'
import IconTech from '../images/icons/Tech_Shapes.svg'

const getIcon = (service: string) => {
  switch (service) {
    case 'arch':
      return <IconArch />
    case 'gov':
      return <IconGov />
    case 'strat':
      return <IconStrat />
    case 'tech':
      return <IconTech />
    default:
      return <IconTech />
  }
}

export interface ICardProps {
  title: string
  content: string
  icon: string
  [x: string]: any
}

const CardContainer = styled.div`
  background: ${colors.brand};
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  max-width: 450px;
  min-width: 320px;
  width: 100%;
  padding: 50px 50px 20px 50px;
  margin: 0 10px 20px;
  transition: all 0.3s ease-out;
`
const CardHeading = styled.h3`
  color: ${colors.white};
  position: relative;
  padding-top: 0.5em;
  margin: 2em 0 1em;
  font-size: ${getEmSize(24)}em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    background: ${colors.white};
    height: 2px;
  }
`

const CardServices: React.FC<ICardProps> = ({ width, title, content, icon, ...props }) => (
  <CardContainer {...props}>
    <div
      css={css`
        height: 170px;
        display: flex;
        align-items: center;
      `}
    >
      {getIcon(icon)}
    </div>
    <CardHeading>{title}</CardHeading>
    <p
      css={{
        color: colors.white,
        opacity: 0.6,
        fontSize: `${getEmSize(20)}em`
      }}
    >
      {content}
    </p>
  </CardContainer>
)

export default CardServices
