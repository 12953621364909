import * as React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import 'modern-normalize'
import '../styles/normalize'

import Header from '../components/Header'
import Footer from '../components/Footer'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
      siteUrl: string
      logo: string
    }
  }
}

const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1600px'],
  space: [0, 4, 8, 16, 32, 64, 128, 256]
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <ParallaxProvider>
        <ThemeProvider theme={theme}>
          <LayoutRoot>
            <Helmet
              title={data.site.siteMetadata.title}
              meta={[
                { name: 'description', content: data.site.siteMetadata.description },
                { name: 'keywords', content: data.site.siteMetadata.keywords },
                { name: 'og:url', content: data.site.siteMetadata.siteUrl },
                { name: 'og:title', content: data.site.siteMetadata.title },
                { name: 'og:image', content: data.site.siteMetadata.logo }
              ]}
            />
            <Header
              title={data.site.siteMetadata.title}
              links={[
                {
                  name: 'Services',
                  href: '#services'
                },
                {
                  name: 'Projects',
                  href: '#experience'
                },
                {
                  name: 'About Us',
                  href: '#team'
                },
                {
                  name: 'Career',
                  href: '#positions'
                },
                {
                  name: 'Contact',
                  href: '#contact'
                }
              ]}
            />
            <LayoutMain>{children}</LayoutMain>
            <Footer copyright="Copyright" />
          </LayoutRoot>
        </ThemeProvider>
      </ParallaxProvider>
    )}
  />
)

export default IndexLayout
