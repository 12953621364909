import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'

import SectionContainer from './SectionContainer'
import CardExperience, { ICardProps } from './CardExperience'
import { colors, widths, mq } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { useSalProps } from '../helpers/useSalAnim'

const StyledContainer = styled(SectionContainer)`
  background-color: ${colors.gray.light};
  flex-direction: column;
  padding: 50px 0;
`

const SectionHeading = styled.h2`
  text-transform: uppercase;
  text-align: center;
  color: ${colors.black};
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 1.5em;
  font-size: ${getEmSize(45)}em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    width: 80px;
    background: ${colors.black};
    height: 3px;
  }
`

const Cards = styled(Flex)`
  width: 100%;
  max-width: ${widths.maxOuter}px;
  justify-content: center;
  align-self: center;
  margin-bottom: 50px;
  z-index: 200;
`

interface SectionProps {
  id?: string
  className?: string
}

const SectionExperience = () => (
  <StaticQuery
    query={graphql`
      query ClientsFromJSON {
        images: allFile(filter: { absolutePath: { regex: "/logos/" } }) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width: 350) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        datafromjson: allExperienceJson {
          edges {
            node {
              clients {
                title
                content
                logo
                website
                caseStudy
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { images, datafromjson } = data
      const salProps = useSalProps()
      return (
        <StyledContainer id="experience">
          <SectionHeading {...salProps}>Project Experience</SectionHeading>
          <Cards flexWrap="wrap">
            {datafromjson.edges[0].node.clients.map((client: ICardProps, index: number) => {
              const clientIndex = images.edges.findIndex(
                y =>
                  y.node.name ===
                  client.logo
                    .split('.')
                    .slice(0, -1)
                    .join('.')
              )
              const logo = images.edges[clientIndex].node.childImageSharp.fixed
              return (
                <CardExperience
                  key={index}
                  title={client.title}
                  logo={logo}
                  content={client.content}
                  website={client.website}
                  pdf={client.caseStudy}
                  caseStudy={client.caseStudy}
                  width={[1, 1 / 2, 1 / 4]}
                  {...salProps}
                />
              )
            })}
          </Cards>
        </StyledContainer>
      )
    }}
  />
)

export default SectionExperience
