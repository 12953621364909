import * as React from 'react'
import styled from '@emotion/styled'
import { Flex, Box } from 'reflexbox'

import SectionContainer from './SectionContainer'
import CardServices, { ICardProps } from './CardServices'
import ParallaxBgServices from './ParallaxBgServices'
import { colors, widths, mq } from '../styles/variables'
import { getEmSize } from '../styles/mixins'
import { useSalProps } from '../helpers/useSalAnim'

import JSONData from '../content/services.json'

const StyledContainer = styled(SectionContainer)`
  background-color: ${colors.brandDark};
  flex-direction: column;
  justify-content: center;
  min-height: 800px;
  overflow: hidden;
`
const ServicesContainer = styled(Flex)`
  width: 100%;
  justify-content: center;
  margin: 50px auto 10px;
  max-width: ${widths.maxInner}px;
  z-index: 100;
  ${mq('md')} {
    margin: 125px auto 10px;
  }
`

const CenteredContent = styled(Box)`
  padding: 30px;
  ${mq('md')} {
    padding: 50px;
  }
`

const SectionHeading = styled.h2`
  text-transform: uppercase;
  color: ${colors.white};
  position: relative;
  padding-top: 0.5em;
  margin-bottom: 1em;
  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 80px;
    background: ${colors.white};
    height: 3px;
  }
`

const Cards = styled(Flex)`
  width: 100%;
  max-width: ${widths.maxOuter}px;
  justify-content: center;
  align-self: center;
  margin-bottom: 150px;
  z-index: 200;
`

interface SectionProps {
  id?: string
  className?: string
}

const SectionServices: React.FC<SectionProps> = ({ className, id }) => {
  const salProps = useSalProps()
  return (
    <StyledContainer id={id} className={className}>
      <ParallaxBgServices />
      <ServicesContainer>
        <CenteredContent width={[1, 9 / 10, 4 / 5]}>
          <SectionHeading {...salProps}>{JSONData.heading}</SectionHeading>
          <p
            css={{
              fontSize: `${getEmSize(24)}em`,
              color: colors.white
            }}
            {...salProps}
            data-sal-anim="200"
          >
            {JSONData.intro}
          </p>
        </CenteredContent>
      </ServicesContainer>
      <Cards flexWrap="wrap">
        {JSONData.cards.map<ICardProps>((card: ICardProps, index: number) => (
          <CardServices
            key={index}
            title={card.title}
            icon={card.icon}
            content={card.content}
            width={[1, 1 / 2, 1 / 4]}
            {...salProps}
          ></CardServices>
        ))}
      </Cards>
    </StyledContainer>
  )
}

export default SectionServices
